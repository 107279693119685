import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

import {
    registerComponent, // import the registerComponent function used to register the components
    Eventcalendar, // import component classes that needed to be regitered
    Datepicker,
    Button,
    Checkbox,
} from '@mobiscroll/jquery';

// register components for jQuery
registerComponent(Eventcalendar);
registerComponent(Datepicker);
registerComponent(Button);
registerComponent(Checkbox);
