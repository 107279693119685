import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datep"
export default class extends Controller {
  connect() {

    $(".datepicker").mobiscroll().datepicker({

      dateFormat: 'YYYY-MM-DD'
    });

  
  }
}
