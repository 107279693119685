import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datetimep"
export default class extends Controller {
  connect() {
    $('.timepicker').mobiscroll().datepicker({
        controls: ['datetime'],
        dateFormat: 'YYYY-MM-DD',
        timeFormat: 'H:mm',
        min: '2000-01-01T12:00',
        max: '2050-01-01T12:00'
    });

  }
}
